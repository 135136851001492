const verificationCode = ref();
const showRetryButton = ref(false);
const showVerificationInput = ref(false);
const timeLeft = ref(180)
let timer;

export default () => {


    const remainingTime = computed(() => {
        const minutes = Math.floor(timeLeft.value / 60).toString().padStart(2, '0');
        const seconds = (timeLeft.value % 60).toString().padStart(2, '0');

        return `${minutes}:${seconds}`;
    })

    const startTimer = () => {
        clearInterval(timer)
        
        timeLeft.value = 180; // 3 dakika
        timer = setInterval(() => {
            if (timeLeft.value > 0) {
                timeLeft.value--;
            } else {
                clearInterval(timer);
                // Süre dolduğunda yapılacak işlemler
                showRetryButton.value = true;
            }
        }, 1000);
    };

    return {
        verificationCode,
        showVerificationInput,
        showRetryButton,
        remainingTime,
        timer,
        startTimer
    }
}