<script setup>
import DiscountPercent from "~/components/DiscountPercent.vue";

const props = defineProps({
  variant : {
    default : {}
  },
  size : {
    default : 'default' // todo : default - large (for large screens & product detail section)
  },
  withDiscountBadge : {
    default : false // TODO
  }
})

const {status, data} = useAuth()

const storeConfig = useStoreConfig()
const { accessType, showRateNextToPrice, withInCartTextForCampaigns, wholesaleConfig } = storeToRefs(storeConfig)

const isWholesaleUser = computed(() => status?.value === 'authenticated' && data?.value?.customer_type === 1)
const hidePricesForGuest = computed(() => status?.value === 'unauthenticated' && wholesaleConfig.value?.hide_price_from_guest)

const {
  showListPriceAndCampaignTogether,
  showProductUnit,
  showListPrice,
  hidePriceForSoldOut,
} = useListing()

const showLoginAlert = true // TODO : parametrik

</script>

<template>
  <template v-if="(variant?.inStock && hidePriceForSoldOut) || !hidePriceForSoldOut">

    <!-- Toptan Fiyat Görünümü -->
    <div class="card-wholesale-price-wrapper" v-if="data?.customer_type === 1 && !hidePricesForGuest">
      <PriceUpdateModeDescriptionForB2B>
        <!-- Üstü çizili fiyatı göster -->
        <div class="flex items-center gap-2.5" v-if="showListPrice">
          <div class="card-list-price">
            {{ formatPrice(variant?.primaryStockData?.wholesaleListPrice, variant?.primaryStockData?.currency) }}
          </div>
          <div class="card-sale-price" v-if="variant?.primaryStockData?.discountPrice > variant?.primaryStockData?.wholesalePrice && showListPriceAndCampaignTogether === 1">
            {{ formatPrice(variant?.primaryStockData?.discountPrice, variant?.primaryStockData?.currency) }}
          </div>
        </div>

        <!-- Cari Fiyatı var ise cari fiyatı göster -->
        <div v-if="variant?.primaryStockData?.customerPrice" class="flex flex-wrap">
          <div class="card-list-price">
            {{ formatPrice(variant?.primaryStockData?.wholesalePrice, variant?.primaryStockData?.currency) }}
          </div>

          <div class="card-sale-price">
            {{ formatPrice(variant?.primaryStockData?.customerPrice, variant?.primaryStockData?.currency) }}
          </div>

          <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
          <SectionProductVatRateInformation class="flex-1" :selected-variant="variant" />
        </div>

        <!-- Toptan fiyat bilgisini göster -->
        <div v-else class="card-campaign-price" :class="discountRateIsExits(variant, isWholesaleUser) ? 'text-campaign-color' : 'text-price-color'">
          <template v-if="discountRateIsExits(variant, isWholesaleUser) && withInCartTextForCampaigns">{{ $t('listing.campaign-in-cart-text', [formatPrice(variant?.primaryStockData?.wholesalePrice, variant?.primaryStockData?.currency)]) }} </template>
          <template v-else>{{formatPrice(variant?.primaryStockData?.wholesalePrice, variant?.primaryStockData?.currency)}}</template>

          <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
          <SectionProductVatRateInformation :selected-variant="variant" />
        </div>
      </PriceUpdateModeDescriptionForB2B>
    </div>

    <!-- Kampanyalı Fiyat Görünümü -->
    <div class="card-price-wrapper" v-else-if="variant?.primaryStockData?.discountPrice > 0 && variant?.primaryStockData?.discountPrice !== variant?.primaryStockData?.price && (isWholesaleUser || accessType !== 'b2b') && !hidePricesForGuest">
      <DiscountPercent :selected-variant="variant" class="!relative !m-0" v-if="showRateNextToPrice"/>

      <div class="campaign-price-wrapper" v-if="showListPrice && showListPriceAndCampaignTogether === 1">
        <div class="card-list-price">
          {{ formatPrice(variant?.primaryStockData?.listPrice, variant?.primaryStockData?.currency) }}
        </div>
        <div class="card-sale-price with-campaign">
          {{ formatPrice(variant?.primaryStockData?.price, variant?.primaryStockData?.currency) }}
        </div>
      </div>

      <div class="card-list-price" v-else>
        {{ formatPrice(variant?.primaryStockData?.price, variant?.primaryStockData?.currency) }}
      </div>

      <div class="card-campaign-price" :class="discountRateIsExits(variant, isWholesaleUser) ? 'text-campaign-color' : 'text-price-color'">
        <template v-if="discountRateIsExits(variant, isWholesaleUser) && withInCartTextForCampaigns">{{ $t('listing.campaign-in-cart-text', [formatPrice(variant?.primaryStockData?.discountPrice, variant?.primaryStockData?.currency)]) }} </template>
        <template v-else>{{formatPrice(variant?.primaryStockData?.discountPrice, variant?.primaryStockData?.currency)}}</template>
        <span class="card-unit-label" v-if="showProductUnit && variant?.unitName"> / {{variant?.unitName}}</span>
      </div>

      <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
      <SectionProductVatRateInformation :selected-variant="variant" />
    </div>

    <!-- Kampanyasız Fiyat Görünümü -->
    <div v-else-if="(isWholesaleUser || accessType !== 'b2b') && !hidePricesForGuest">
      <div class="card-price-wrapper">
        <DiscountPercent :selected-variant="variant" class="!relative !m-0" v-if="showRateNextToPrice"/>

        <div class="card-list-price" v-if="showListPrice && variant?.primaryStockData?.listPrice">
          {{ formatPrice(variant?.primaryStockData?.listPrice, variant?.primaryStockData?.currency) }}
        </div>

        <div class="card-sale-price">
          {{ formatPrice(variant?.primaryStockData?.price, variant?.primaryStockData?.currency) }}
          <span class="card-unit-label" v-if="showProductUnit && variant?.unitName"> / {{variant?.unitName}}</span>
        </div>
      </div>

      <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
      <SectionProductVatRateInformation :selected-variant="variant" />
    </div>

    <!-- Fiyatları görebilmek için bayi girişi yapınız -->
    <div class="text-sm text-gray-500" v-else-if="hidePricesForGuest && showLoginAlert">
      <i18n-t keypath="listing.please-login-for-the-prices" tag="div">
        <a href="/login" class="text-login">{{$t('listing.dealer-login')}}</a>
      </i18n-t>
    </div>

  </template>
</template>