import {defineStore} from "pinia";
import collect from "collect.js";

export const useStoreConfig = defineStore('storeConfig', () => {
    const {status, data: userData} = useAuth()

    // Base config
    const themeConfig = ref({})
    const baseConfig = ref({})
    const productConfig = ref({})
    const listingConfig = ref({})
    const footerConfig = ref({})
    const logoConfig = ref({})
    const checkoutConfig = ref({})
    const currencyConfig = ref({})
    const wholesaleConfig = ref({})
    const contactConfig = ref({})
    const registrationConfig = ref({})
    const marketplaceApps = ref({})
    const menuConfig = ref({})

    // Navigation
    const navigationMenu = computed(() =>  menuConfig.value?.navigation)
    const topMenu = computed(() => menuConfig.value?.top)
    const footerMenu = computed(() => menuConfig.value?.footer)
    const featuredMenu = computed(() => menuConfig.value?.featured)
    const footerLabels = computed(() => footerConfig.value?.footer_labels)

    const redirectToAfterSignedIn = useCookie('redirectTo')

    // Computed
    const themeComponents = computed(() => themeConfig.value?.components)
    const isStickyNavigation = computed(() => themeConfig.value?.sticky_menu)
    const isMaintenanceMode = computed(() => baseConfig.value?.is_maintenance)
    const accessType = computed(() => baseConfig.value?.access_type)
    const showRateNextToPrice = computed(() => themeConfig.value?.show_rate_next_to_price)
    const showSearchRecommendations = computed(() => themeConfig.value?.show_search_recommendations)
    const withInCartTextForCampaigns = computed(() => themeConfig.value?.with_in_cart_text_for_campaigns)

    // Şimdili sadece B2B'de kullanıyoruz
    const quickViewIsActive = computed(() => status?.value === 'authenticated' && userData?.value?.customer_type === 1 && themeConfig.value?.quick_view_mode_is_active)

    // Computed > B2B bilgileri
    const isB2BAccount = computed(() => baseConfig.value?.is_b2b_account)
    const isB2BActive = computed(() => baseConfig.value?.is_b2b_active)
    const restrictAccessForUnauthorizedVisitors = computed(() => wholesaleConfig.value?.restrict_access && status?.value === 'unauthenticated')
    const b2bSearchTemplate = computed(() => wholesaleConfig.value?.search_template ? wholesaleConfig.value?.search_template : 'list')
    const b2bCategoryTemplate = computed(() => wholesaleConfig.value?.category_template ? wholesaleConfig.value?.category_template : 'grid')
    const availableRegisterFields = computed(() => wholesaleConfig.value?.availableRegisterFields ? wholesaleConfig.value?.availableRegisterFields : [])

    // Computed > Bölge yönetimi kontrolleri
    const isRegionControlIsActive = computed(() => baseConfig.value?.is_region_control_is_active)
    const isRegionForceSelection = computed(() => baseConfig.value?.is_region_force_selection)
    const isRegionSelectionVisible = computed(() => baseConfig.value?.is_region_selection_visible)
    const accountDecimalRate = computed(() => baseConfig.value?.decimal_rate ? baseConfig.value?.decimal_rate : 2)
    const languageSelectionIsActive = computed(() => baseConfig.value?.language_selection_is_active)

    // Computed > Sepet teklif değişkenleri
    const useCartOfferFeature = computed(() => checkoutConfig.value?.accept_offer)
    const cartOfferMinValue = computed(() => checkoutConfig.value?.min_value_for_accept_offer)
    const checkoutOfferDescription = computed(() => checkoutConfig.value?.checkout_offer_description)
    const virtualPosProvider = computed(() => baseConfig.value?.virtual_pos_provider)

    const qtyChangeMethod = computed(() => checkoutConfig.value?.qty_change_method ? checkoutConfig.value?.qty_change_method : 0)
    const updateLineInBasket = computed(() => !checkoutConfig.value?.add_separate_line)
    const showLocalCurrencyTotals = computed(() => checkoutConfig.value?.show_local_currency_totals)
    const showModelIdInBasket = computed(() => checkoutConfig.value?.show_model_id)
    const shipmentDescription = computed(() => checkoutConfig.value?.shipment_description)
    const showPromoCode = computed(() => checkoutConfig.value?.accept_promo)

    // Computed -> Döviz bilgisi
    const currency = computed(() => status?.value === 'authenticated' && userData?.value?.customer_type === 1 && currencyConfig.value?.defaultCurrencyForB2B ? currencyConfig.value?.defaultCurrencyForB2B : currencyConfig.value?.defaultCurrency)

    const exchangeRates = computed(() => currencyConfig.value?.exchange?.TRY)

    // Computed -> Logo
    const logo = computed(() => logoConfig.value?.logo)
    const logoForMobile = computed(() => logo.value?.logo_mobile)
    const isMobileLogoSeparate = computed(() => logo.value?.is_mobile_logo_separate)

    // Computed -> Etbis
    const etbis = computed(() => {
        return {
            url : baseConfig.value?.etbis_url,
            image : baseConfig.value?.etbis_image
        }
    })

    // Marketplace Apps
    const gaConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 3).first())

    const googleAnalyticsConfig = computed(() => {
        return {
            isActive : gaConfigPayload.value?.is_active,
            IdentityId : collect(gaConfigPayload.value?.config).where('labelKey', '=', 'IdentityId').pluck('value').first(),
            adsConfigId : collect(gaConfigPayload.value?.config).where('labelKey', '=', 'adsConfigId').pluck('value').first(),
            adsConversationId : collect(gaConfigPayload.value?.config).where('labelKey', '=', 'adsConversationId').pluck('value').first()
        }
    })

    const fbConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 5).first())
    const facebookPixelConfig = computed(() => {
        return {
            isActive : fbConfigPayload.value?.is_active,
            pixelId : collect(fbConfigPayload.value?.config).where('labelKey', '=', 'pixelId').pluck('value').first()
        }
    })

    const znConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 2).first())
    const zendeskConfig = computed(() => {
        return {
            isActive : znConfigPayload.value?.is_active,
            apiKey : collect(znConfigPayload.value?.config).where('labelKey', '=', 'apiKey').pluck('value').first()
        }
    })

    const htConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 4).first())
    const hotjarConfig = computed(() => {
        return {
            isActive : htConfigPayload.value?.is_active,
            siteId : collect(htConfigPayload.value?.config).where('labelKey', '=', 'hotjarSiteId').pluck('value').first()
        }
    })

    const ydConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 6).first())
    const yandexMetricaConfig = computed(() => {
        return {
            isActive: ydConfigPayload.value?.is_active,
            metricaId: collect(ydConfigPayload.value?.config).where('labelKey', '=', 'metricaId').pluck('value').first()
        }
    })

    // Google Tag Manager
    const gtConfigPayload = computed(() => collect(marketplaceApps.value).where('app_id', '=', 7).first())
    const gTagConfig = computed(() => {
        return {
            isActive : gtConfigPayload.value?.is_active,
            gtmId : collect(gtConfigPayload.value?.config).where('labelKey', '=', 'gtmId').pluck('value').first()
        }
    })

    const scriptTags = computed(() => {
        return collect(marketplaceApps.value).where('app_id', '=', 9).where('is_active', '=', true).map((i) => {
            return {
                src : collect(i?.config).where('labelKey', '=', 'src').pluck('value').first(),
                defer : collect(i?.config).where('labelKey', '=', 'defer').pluck('value').first(),
                async : collect(i?.config).where('labelKey', '=', 'async').pluck('value').first(),
                type : collect(i?.config).where('labelKey', '=', 'type').pluck('value').first()
            }
        }).toArray()
    })

    const metaTags = computed(() => {
        return collect(marketplaceApps.value).where('app_id', '=', 10).where('is_active', '=', true).map((i) => {
            return {
                name : collect(i?.config).where('labelKey', '=', 'name').pluck('value').first(),
                content : collect(i?.config).where('labelKey', '=', 'content').pluck('value').first()
            }
        })
    })

    const whatsAppConfig = computed(() => collect(marketplaceApps.value).where('app_id', '=', 1).first())

    const isProductVariableCoefficientUnitCalculationIsActive = computed(() => wholesaleConfig.value?.availableSearchFields?.filter((i) => i.value === 'base::variableCoefficientUnitCalculation')?.length)
    const isProductVariableCoefficientUnitSumIsActive = computed(() => wholesaleConfig.value?.availableSearchFields?.filter((i) => i.value === 'base::variableCoefficientUnitSum')?.length)

    const subscriptionIsActive = computed(() => baseConfig.value?.subscription_is_active)

    // Ayarları getir
    const fetch = async () => {

        const headers = useRequestHeaders(['cookie'])
        const {data: siteConfig} = await useAsyncData("siteConfig", () => $fetch('/api/config', {
            headers
        }), {
            key: "siteConfig"
        })

        // Tüm ayarları set ediyoruz
        baseConfig.value = siteConfig.value?.base_config
        themeConfig.value = siteConfig.value?.theme
        listingConfig.value = siteConfig.value?.listing
        wholesaleConfig.value = siteConfig.value?.wholesale
        productConfig.value = siteConfig.value?.product
        logoConfig.value = siteConfig.value?.logo
        checkoutConfig.value = siteConfig.value?.checkout
        footerConfig.value = siteConfig.value?.footer
        contactConfig.value = siteConfig.value?.contact
        currencyConfig.value = siteConfig.value?.currency
        registrationConfig.value = siteConfig.value?.registration
        marketplaceApps.value = siteConfig.value?.marketplace_apps
        menuConfig.value = siteConfig.value?.menu

        redirectToAfterSignedIn.value = siteConfig.value?.registration?.redirect_to ? siteConfig.value?.registration?.redirect_to : '/'

    }

    return {
        fetch,
        themeConfig,
        baseConfig,
        productConfig,
        listingConfig,
        footerConfig,
        logoConfig,
        checkoutConfig,
        currencyConfig,
        wholesaleConfig,
        contactConfig,
        registrationConfig,
        menuConfig,
        marketplaceApps,
        navigationMenu,
        topMenu,
        footerMenu,
        featuredMenu,
        footerLabels,
        isMaintenanceMode,
        isStickyNavigation,
        themeComponents,
        logo,
        logoForMobile,
        isMobileLogoSeparate,
        currency,
        useCartOfferFeature,
        cartOfferMinValue,
        checkoutOfferDescription,
        isRegionControlIsActive,
        isRegionForceSelection,
        isRegionSelectionVisible,
        isB2BAccount,
        isB2BActive,
        accessType,
        restrictAccessForUnauthorizedVisitors,
        virtualPosProvider,
        googleAnalyticsConfig,
        gTagConfig,
        facebookPixelConfig,
        zendeskConfig,
        hotjarConfig,
        yandexMetricaConfig,
        scriptTags,
        metaTags,
        whatsAppConfig,
        etbis,
        exchangeRates,
        updateLineInBasket,
        qtyChangeMethod,
        redirectToAfterSignedIn,
        isProductVariableCoefficientUnitCalculationIsActive,
        isProductVariableCoefficientUnitSumIsActive,
        showRateNextToPrice,
        withInCartTextForCampaigns,
        accountDecimalRate,
        showLocalCurrencyTotals,
        showModelIdInBasket,
        b2bSearchTemplate,
        shipmentDescription,
        showSearchRecommendations,
        b2bCategoryTemplate,
        languageSelectionIsActive,
        subscriptionIsActive,
        quickViewIsActive,
        showPromoCode,
        availableRegisterFields
    }
})