<script setup lang="ts">
const props = defineProps(['selectedVariant'])
const {isVatExcludeForB2B, hidePricesForGuest, shownVatIncludeText} = useHelper()
</script>

<template>
  <div class="product-vat-info" v-if="(isVatExcludeForB2B || (!isVatExcludeForB2B && !selectedVariant?.primaryStockData?.vatInclude)) && selectedVariant?.primaryStockData?.vatRate > 0">{{$t('widgets.vat-info.excluded', {vatRate : selectedVariant?.primaryStockData?.vatRate})}}</div>
  <div class="product-vat-info" v-else-if="shownVatIncludeText && selectedVariant?.primaryStockData?.vatInclude && selectedVariant?.primaryStockData?.vatRate > 0">{{$t('widgets.vat-info.included', {vatRate : selectedVariant?.primaryStockData?.vatRate})}}</div>
</template>

<style scoped>

</style>