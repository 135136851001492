<script setup>
//import {collect} from "collect.js";

const props = defineProps(['product'])

const storeConfig = useStoreConfig()
const { baseConfig } = storeToRefs(storeConfig)

const {isMobile} = useDevice()

const visibleVariants = computed(() => props.product?.variantGroups?.slice(0, 5))

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

// const variants = await collect(props.product.variants);

const setSelectedVariant = (variant) => {
  selectedVariant.value = variant;
}

</script>

<template>
  <div class="product-card-group-options-wrapper" v-if="product?.variantGroups.length > 1">
    <div class="hidden md:group-hover:flex" v-if="!baseConfig?.index_products_without_variant_group && !isMobile">
      <div class="flex items-center h-full w-full">
        <div
            v-for="(variant,index) in visibleVariants"
            :key="variant.variantId + '-' + index"
            @mouseenter="setSelectedVariant(variant)"
            class="mr-2 mt-2 border-b-4 hover:opacity-80 cursor-pointer ring-2 ring-transparent hover:ring-primary"
        >
          <NuxtImg
              v-if="variant?.thumbnail"
              :src="getImage(variant.thumbnail)"
              alt="index"
              class="object-contain"
          />
          <NuxtImg
              v-else
              fit="outside"
              alt=""
              class="object-contain aspect-2/3"
              src="/images/no-image.png"
          />
        </div>

        <NuxtLinkLocale :href="selectedVariant?.url ? selectedVariant.url :'#'"
                  v-if="product?.variantGroups.length - 5 > 0">
          <div class="h-10 w-8 flex justify-center items-center text-base hover:border-primary font-medium">
            +{{ product?.variantGroups.length - 5 }}
          </div>
        </NuxtLinkLocale>
      </div>
    </div>
    <div class="product-badge absolute left-0 bottom-0" v-else-if="product?.variantGroups.length > 1 && selectedVariant?.inStock">
      {{$t('widgets.group-card-options.n-option', {count : product?.variantGroups.length})}}
    </div>
  </div>
</template>

<style>
  .product-options-badge {
    @apply absolute bottom-0 right-0 flex bg-black/10 text-xs px-1.5 py-1 rounded-lg mb-4 mr-1 font-medium;
  }
</style>