<template>
  <component :is="ProductTemplate" :product="product"/>
</template>

<script setup>

const props = defineProps(['product'])
const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`, () => props.product?.selectedVariant);

const storeConfig = useStoreConfig()
const { baseConfig, themeComponents } = storeToRefs(storeConfig)

const ProductTemplate = shallowRef();
switch (unref(themeComponents)?.product_card_component) {
  case '2':
    ProductTemplate.value = resolveComponent('TemplatesProductCard2')
    break;
  case '3':
    ProductTemplate.value = resolveComponent('TemplatesProductCard3')
    break;
  case '4':
    ProductTemplate.value = resolveComponent('TemplatesProductCard4')
    break;
  case '5':
    ProductTemplate.value = resolveComponent('TemplatesProductCard5')
    break;
  case '6':
    ProductTemplate.value = resolveComponent('TemplatesProductCard6')
    break;
  case 'CardUnver':
    ProductTemplate.value = resolveComponent('TemplatesProductCardUnver')
    break;
  case 'CardUzman':
    ProductTemplate.value = resolveComponent('TemplatesProductCardUzman')
    break;
  case 'CardAirlife':
    ProductTemplate.value = resolveComponent('TemplatesProductCardAirlife')
    break;
  case 'CardAirlifeWholesale':
    ProductTemplate.value = resolveComponent('TemplatesProductCardAirlifeWholesale')
    break;
  default :
    ProductTemplate.value = resolveComponent('TemplatesProductCard1');
}

// Ön tanımlı ürün görseli
const hoverImage = ref(`selectedVariantImageIsHover::${props.product.productId}::${props.product.productGroupValue}`, () => false);
const sourceImage = useState(`selectedVariantImage::${props.product.productId}::${props.product.productGroupValue}`, () => {
  if (selectedVariant.value?.xyImages?.length > 0) {
    return hoverImage.value === true ? selectedVariant.value?.xyImages[0] : selectedVariant.value.xyImages[1]
  } else {
    return selectedVariant.value?.thumbnail
  }
});

const isShowcaseMode = useState(`isShowcaseMode::${unref(selectedVariant)?.variantId}`, () => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0);

watch(selectedVariant, (variant) => {
  if (variant?.xyImages?.length > 0) {
    sourceImage.value = hoverImage.value === true ? variant?.xyImages[0] : variant.xyImages[1]
  } else {
    sourceImage.value = variant.thumbnail
  }
})
</script>