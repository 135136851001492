<template>
  <div class="product-features-wrapper" v-if="product?.features.length">
    <template v-for="item in product.features">
      <div class="py-2" v-if="item.attributeItemName">
        <span class="font-medium">{{ item.attributeName }}</span> : {{ item.attributeItemName }}
      </div>
    </template>
  </div>
</template>

<script setup>
const {
  product
} = await useProduct()
</script>